import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 5×2\\@90% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`25-Deadlifts (185/125)`}</p>
    <p>{`25-T2B`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`20-Deadlifts`}</p>
    <p>{`20-T2B`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`15-Deadlifts`}</p>
    <p>{`15-T2B`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      